.imgPickerContainer {
  
    margin: 18px;
    height: 224px;
    width: 224px;
    transition: transform .2s;
    position: relative;


}
.imgPicker {
  
  max-height: 222px;
  width: 100%;

}
button:hover {
  cursor: pointer;
}
button {
  border-width: 0;
}

.selected-container {
    
  border-radius: 3em;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
}
.selectory{
    
  position: absolute;
  top:calc(50% - 25px);
  padding-top: 0px;
  font-size: 24px;
  width: calc(100% - 48px);
  height: 50px;
  left: 25px;
  background-color: #00b8ff;
  border-radius: 3em;
  text-align: center;
  z-index: 3;
  opacity: .75;
  font-weight: bolder;
  padding-top: 10px;
  border: none;
  border-width: 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  
}
.approve:hover{
  background-color: #00b8ff;
}
.approve{
    
  position: absolute;
  top:calc(50% - 60px);
  padding: 10px;
  font-size: 18px;
  width: calc(100% - 48px);

  left: 25px;
  background-color: #4dccff;
  border-radius: 3em;
  text-align: center;
  z-index: 5;
  border: none;
  border-width: 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.cancel:hover{
  background-color: #ffa280;
}
.cancel{
    
  position: absolute;
  top:calc(50% + 20px);
  padding: 10px;
  font-size: 18px;
  width: calc(100% - 48px);

  left: 25px;
  background-color: #ffc7b3;
  border-radius: 3em;
  text-align: center;
  z-index: 5;
  border: none;
  border-width: 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.selected {
    
    height: 222px;
    width: 222px;
    opacity: .5;

 }



 .container {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;
    justify-content: center;
    border-radius: 3em;
    
 }

.a-un:hover {
  transform: scale(1.1);

}
.collection:hover {
  transform: scale(1.1);

}
.infinite-scroll-component__outerdiv > div > div{
margin: 18px;
height: 224px;
width: 224px;
transition: transform .2s;
position: relative;
}
.infinite-scroll-component {
height: auto;
overflow: auto;
display: flex;
flex-wrap: wrap;
margin: auto;
justify-content: center;

}



.floor{
  position: absolute;
  bottom: 10px;
  left: 15%;
  width: 70%;
  text-align: center;
  padding: 5px;
  border: none;
  border-radius: 3em;
  z-index: 1;
  font-size: 10px;
  font-weight: bolder;
  background-color: #efefef;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.a-selected {
  position: relative;
  min-width: 220px;
    min-height: 220px;
    display: table;
    border-radius: 3em;
    overflow: hidden;
    border: none;
    border-width: 1px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  
}
.a-unBlink{
  min-width: 220px;
  min-height: 224px;
  display: table;
  border-radius: 3em;
  overflow: hidden;
  animation: blink 5s;
  animation-iteration-count: infinite;
  border: 3px solid #4dccff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.recommended{
  animation: blinktext 5s;
  animation-iteration-count: infinite;
  background-color: #4dccff;
  color: #e63d00;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-bottom-right-radius: 1em;
  border-top-left-radius: 1em;

}
@keyframes blink { 50% { border-color: #e63d00; }  }
@keyframes blinktext { 50% { color: #4dccff; background-color: #e63d00; }  }
.hide{
  display: none;
}
.a-unBlink:hover {
  transform: scale(1.1);

}
.a-un{
  min-width: 220px;
  min-height: 224px;
  display: table;
  border-radius: 3em;
  overflow: hidden;
  border: none;
  border-width: 1px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}
.searchWrapper{
  border: none;
  margin-top: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 3em;
  padding-left: 10px;
  padding-right: 10px;

}
.chip{
  background-color: #00b8ff;
  font-size: 16px;
  height: 40px;
  border-radius: 3em;
  margin-top: 5px;
}
.searchBox::placeholder{
  font-size: 16px;
  padding-left: 5px;
}
.searchBox{
  min-width: 165px;
  padding: 5px;
}
.optionListContainer{
  z-index: 10;
}
.opensea{
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  z-index: 2;
  border: none;
  border-radius: 36px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;


}
.opensea:hover{
  border: 1px solid #00b8ff;
  border-radius: 36px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.scrollableDivVault{
  height: calc(100vh - 375px)
}
.searchBoxVault input{
  border: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  margin-top: 4px;
    border-radius: 3em;
    padding-left: 20px;
    padding-right: 10px;
    min-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
    background: transparent;
    font-size: 20px;
}
.searchBoxVault fieldset{
  border: 0;
}
.searchBoxVault label{
  top: -5px;
  left: 10px;
  color: #100b09;
    line-height: 1.5;
    font-size: 1rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    opacity: .6;
}
.searchBoxVault{
  width: 100%;
}
